










import Vue from 'vue'
export default Vue.extend({
	computed: {
		lang () : any { return (this as any).$lang(); }
	}
})
