import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
	mode: 'history',
	// base: process.env.BASE_URL,
	routes: [
		{
			name: 'main',
			path: '/:lang',
			component: () => import('@/views/Home.vue')
		},
		// CATEGORY
		{
			name: 'category',
			path: '/:lang/category/:slug',
			component: () => import('@/views/Category.vue')
		},
		// ARTICLE
		{
			name: 'article',
			path: '/:lang/article/:slug',
			component: () => import('@/views/Article.vue')
		},
		// ISSUE
		{
			name: 'issue',
			path: '/:lang/issue/:id',
			component: () => import('@/views/Issue.vue')
		},
		// SEARCH RESULTS
		{
			name: 'search',
			path: '/:lang/search/:term',
			component: () => import('@/views/Search.vue')
		},
		// CONTACT
		{
			name: 'contact',
			path: '/:lang/contact',
			component: () => import('@/views/Contact.vue')
		}
	]
});

router.beforeEach((_to, _from, next) => {
	document.documentElement.scrollTop = 0;
	if (window && window['ga']) {
		window['ga']('set', 'page', _to.fullPath);
		window['ga']('send', 'pageview');
	}
	next();
});

export default router;
