import '@babel/polyfill';
import Vue from 'vue';
import './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

import { VueMasonryPlugin } from 'vue-masonry';

import '@/assets/styles/common.styl';

Vue.config.productionTip = false;
Vue.use(VueMasonryPlugin);

Vue.prototype.$dispatch = function (name: string, data: Object) {
	if (!window) { return null; }
	window.dispatchEvent(new CustomEvent(name, { detail: data }));
}

Vue.prototype.$lang = function () {
	return this.$store.getters.locale;
}

Vue.filter('tsToString', function (timestamp:number):string {
	const d = new Date(timestamp * 1000);
	const year = d.getFullYear().toString();
	let day = d.getDate().toString();
	let month = (d.getMonth() + 1).toString();

	if (day.length === 1) { day = '0' + day; }
	if (month.length === 1) { month = '0' + month; }

	return `${day}.${month}.${year}`;
});

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')
