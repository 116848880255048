
























import MainMenu from './components/MainMenu.vue';
import Toolbar from './components/Toolbar.vue';
import Header from './components/Header.vue';
import Vue from 'vue';

export default Vue.extend({
	components: { MainMenu, Toolbar, Header },
	computed: {
		lang () : any { return (this as any).$lang(); }
	},
	beforeMount () {
		const pathname = window.location.pathname;
		if ((!pathname.startsWith('/ka/') && !pathname.startsWith('/en/')) || pathname.startsWith('/ka/')) {
			this.$store.commit('locale', 'ka');
			// @ts-ignore
			document.styleSheets[document.styleSheets.length - 1].addRule(
				'*', 'font-family: "BPG_NINO"');
		} else if (pathname.startsWith('/en/')) {
			this.$store.commit('locale', 'en');
			// @ts-ignore
			document.styleSheets[document.styleSheets.length - 1].addRule(
				'*', 'font-family: "Georgia, Serif"');
		}
		this.$i18n.locale = this.$store.getters.locale;
	}
});
