





























import Vue from 'vue';

export default Vue.extend({
	data () {
		return {
			searchTerm: '',
			drawer: false
		};
	},
	beforeMount () {
		this.$store.dispatch('fetch', {
			storeKey: 'mainMenu',
			endpoint: 'module',
			fragment: 'menu/',
			store: true
		});
	},
	computed: {
		menu () : any { return this.$store.getters.mainMenu; },
		lang () : any { return (this as any).$lang(); },
		isXS () : any { return this.$vuetify.breakpoint.xs; }
	},
	methods: {
		changeLocale (locale:string) {
			this.$i18n.locale = locale;
			this.$store.commit('locale', locale);
			this.$store.dispatch('setLang', {
				data: { language: locale }
			}).then(_ => {
				window.location.href = `/${locale}/`;
			});
		},
		search () {
			if (this.searchTerm.length > 2) {
				this.$router.push({
					name: 'search',
					params: { term: this.searchTerm }
				});
			}
		}
	}
});
