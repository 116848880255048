

























import Vue from 'vue';
export default Vue.extend({
	beforeMount () {
		this.$store.dispatch('fetch', {
			storeKey: 'mainMenu',
			endpoint: 'module',
			fragment: 'menu/',
			store: true
		});
	},
	computed: {
		menu () : any { return this.$store.getters.mainMenu; },
		lang () : any { return (this as any).$lang(); }
	}
});
