import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

let HOST: string = 'http://127.0.0.1:8000';
HOST = 'https://nargismagazine.ge';

if (process.env.NODE_ENV === 'production') {
	HOST = 'https://nargismagazine.ge';
}

const ENDPOINTS: any = {
	module: `module`,
	content: `content`,
	itegration: `integration`,
	i18n: `i18n/setlang`
};

const AXIOS_BASE_CONFIG = { method: 'POST' };

const procEndpoint = (payload: any) => {
	let language = window.localStorage.getItem('locale');

	if (language !== 'en' && language !== 'ka') {
		language = 'ka';
		window.localStorage.setItem('locale', language);
	}

	const url = `${HOST}/${language}/api/${ENDPOINTS[payload.endpoint]}/`;

	if (payload.fragment) {
		return `${url}${payload.fragment}`;
	} else { return url; }
};

const procFormData = (data: { [index: string]: string }) => {
	const formData = new FormData();

	if (data) {
		Array.from(Object.keys(data)).forEach(key => {
			formData.append(key, data[key]);
		});
	}

	return formData;
};

const procHeaders = (payload: any) => {
	let token = window.localStorage.getItem('token');
	let locale = window.localStorage.getItem('locale');
	let headers = { ...AXIOS_BASE_CONFIG, ...payload.headers };

	if (token && token !== 'null' && token !== 'undefined') {
		headers['Authorization'] = token;
	}
	if (locale && locale !== 'null' && locale !== 'undefined') {
		headers['Accept-Language'] = locale;
	}

	return headers;
};

export default new Vuex.Store({
	state: {
		mainMenu: null,
		mainBlocks: null,
		mainSlider: null,
		recSlider: null,
		issues: null,
		subSliderBlocks: null,

		// DETAIL ITEMS
		category: null,
		article: null,
		issue: null
	},
	getters: {
		mainMenu: (state) => state.mainMenu,
		mainBlocks: (state) => state.mainBlocks,
		mainSlider: (state) => state.mainSlider,
		recSlider: (state) => state.recSlider,
		issues: (state) => state.issues,
		subSliderBlocks: (state) => state.subSliderBlocks,

		category: (state) => state.category,
		article: (state) => state.article,
		issue: (state) => state.issue,

		locale: (_) => {
			const locale = window.localStorage.getItem('locale');
			if (locale !== 'ka' && locale !== 'en') {
				return 'ka';
			} else {
				return locale;
			}
		}
	},
	mutations: {
		locale (_, locale: string) {
			window.localStorage.setItem('locale', locale);
		}
	},
	actions: {
		async fetch (ctx: any, payload) {
			try {
				const res = await axios({
					...AXIOS_BASE_CONFIG,
					params: payload.params || null,
					headers: procHeaders(payload),
					url: procEndpoint(payload),
					method: 'GET'
				});
				const storeKey: string = payload.storeKey || payload.endpoint;
				if (payload.store && payload.merge) {
					if (!ctx.state[storeKey]) {
						ctx.state[storeKey] = [];
					}
					Array.from(res.data).forEach(item => {
						ctx.state[storeKey].push(item);
					});
				} else if (payload.store && !payload.merge) {
					ctx.state[storeKey] = res.data;
				}
				return res;
			} catch (err) {
				console.log('--> fetch err', err, err.response);
			}
		},
		async push (_ctx: any, payload) {
			try {
				return axios({
					...AXIOS_BASE_CONFIG,
					data: procFormData(payload.data),
					method: payload.method || 'POST',
					params: payload.params || null,
					headers: procHeaders(payload),
					url: procEndpoint(payload)
				});
			} catch (err) {
				console.log('--> push err', err, err.response);
			}
		},
		async setLang (_ctx: any, payload) {
			try {
				return axios({
					...AXIOS_BASE_CONFIG,
					data: procFormData(payload.data),
					headers: procHeaders(payload),
					url: `${HOST}/setlang/`,
					method: 'POST'
				});
			} catch (err) {
				console.log('--> setlang err', err, err.response);
			}
		}
	}
});
